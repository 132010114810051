<div *ngIf="loading">
    <div class="align-items-center">
        <p-progressSpinner styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

<div *ngIf="organizationId != null && stats">
    <div class="grid">
        <p-card header="{{'LABELS.users' | translate | titlecase}}" class="col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{stats.users}}</span>
                <i class="pi pi-users text-5xl"></i>
            </div>
        </p-card>
        <p-card header="{{'LABELS.assessments' | translate | titlecase}}" class=" col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{stats.assessments}}</span>
                <i class="pi pi-shield text-5xl"></i>
            </div>
        </p-card>
        <p-card header="{{'LABELS.assets' | translate | titlecase}}" class="col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{stats.assets}}</span>
                <i class="pi pi-server text-5xl"></i>
            </div>
        </p-card>
        <p-card header="{{'LABELS.organizational-units' | translate | titlecase}}" class="col-12 md:col-4 " styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{ stats['organizational-units'] }}</span>
                <i class="pi pi-sitemap text-5xl"></i>
            </div>
        </p-card>
        <p-card header="{{'LABELS.job-titles' | translate | titlecase}}" class="col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{stats['job-titles']}}</span>
                <i class="pi pi-tags text-5xl"></i>
            </div>
        </p-card>
        <p-card header="{{'LABELS.business-processes' | translate | titlecase}}" class="col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{ stats['business-processes'] }}</span>
                <i class="pi pi-book text-5xl"></i>
            </div>
        </p-card>
        <p-card header="{{'LABELS.documents' | translate | titlecase}}" class="col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{ stats.documents }}</span>
                <i class="pi pi-file text-5xl"></i>
            </div>
        </p-card>
    </div>
    <p-button *ngIf="!['organization-user'].includes(userRole!)" [label]="'LABELS.edit-organization' | translate" (onClick)="editOrganizationButtonClicked()" [outlined]=true></p-button>
</div>
