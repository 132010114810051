import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';
import { StatsService } from 'src/app/service/api/stats.service';
import { JwtService } from 'src/app/service/utils/jwt.service';

@Component({
  selector: 'app-organization-dashboard',
  templateUrl: './organization-dashboard.component.html',
  styleUrl: './organization-dashboard.component.scss'
})
export class OrganizationDashboardComponent implements AfterContentInit {

  userRole: string | null;
  organizationId?: string | null;
  stats?: any | null;
  loading = false;

  constructor(
    private statsService: StatsService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private jwtService: JwtService
  ) {

    this.userRole = this.jwtService.getUserRole()
    this.organizationId = this.route.snapshot.paramMap.get('organizationId');
  }

  ngAfterContentInit(): void {
    this.loadOrganizationStats();
  }

  editOrganizationButtonClicked() {
    this.router.navigate(['organizations', this.organizationId, 'edit']);
  }

  async loadOrganizationStats() {
    this.loading = true;
    try {
      const statsResponse = await lastValueFrom(this.statsService.getOrganizationStats(this.organizationId!));
      this.stats = statsResponse.data;
    } catch(e) {
      if (e instanceof HttpErrorResponse) {

      }
      this.logger.error('[organization-dashboard] error retrieving organization\'s stats: ' + JSON.stringify(e));
    }

    this.loading = false;
  }

}
